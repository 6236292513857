<!-- 导入IMEI号 -->
<template>
  <el-card shadow="never" class="">
    <div slot="header" class="clearfix">
      <div class="flex justify-between">
        <div>
          <span class="text-base">填写IMEI号</span>
          <!--          <span class="tips text-xs text-gray-500 ml-2">请确保填写的IMEI与账单中的设备型号一致。</span>-->
        </div>
        <!--        <el-button type="warning" size="small" class="ml-4" icon="el-icon-refresh-left" @click="reSelect">重新选择账单-->
        <!--        </el-button>-->
      </div>
    </div>
    <div class="text-sm" v-if="!testMode">
      License 产品规格
      <el-select size="small" class="ml-2" v-model="billProjectId">
        <el-option
            v-for="(item, index) in licenseProjectList"
            :key="index"
            :label="item.projectName"
            :value="item.id"
            class="h-16 flex justify-between align-items-center"
            style="width: 420px"
        >
          <div class="text-sm font-bold flex flex-col leading-6 w-full">
            <div>项目名称：{{ item.projectName }}</div>
            <div
                class="text-xs font-light flex flex-row justify-between align-items-center"
            >
              <div>单价：{{ item.unitPrice }}元</div>
              <div>
                账单剩余可用：{{
                  item.quantity - item.assignNum
                }}{{ item.unit }}
              </div>
            </div>
          </div>
        </el-option>
      </el-select>
    </div>
    <div class="text-sm" v-if="testMode">
      测试 License 时长
      <el-input-number
          size="small"
          class="ml-4"
          :min="1"
          v-model="licenseNum"
      ></el-input-number>
      <span class="ml-4">天</span>
      <span class="ml-8">
        <span>总数量：{{ testNum.totalNum }}</span>
        <span class="ml-2">已使用数量：{{ testNum.useNum }}</span>
      </span>
    </div>

    <div style="" class="flex align-items-center mt-4">
      <div
          class="w-full flex flex-row justify-center"
          v-if="importType === ''"
      ></div>
      <div v-if="importType === 1" class="w-full">
        <el-button
            size="small"
            v-if="imeiData.length !== 0 && !isShowAddDeviceBatch"
            @click="isShowAddDeviceBatch = true"
            :disabled="isShowAddDeviceBatch"
        >批量填写IMEI号
        </el-button>
        <div
            v-if="imeiData.length === 0 || isShowAddDeviceBatch"
            class="py-4 border border-dashed px-4 mb-4"
        >
          <div class="text-gray-800 text-sm font-bold">请在下方填入IMEI号</div>
          <el-input
              class="mt-2"
              type="textarea"
              :rows="20"
              placeholder="填入IMEI号，一行填写一个IMEI号"
              v-model="addDeviceBatchValue"
          ></el-input>
          <el-button
              class="mt-2"
              size="small"
              type="primary"
              @click="addDeviceBatch"
              :disabled="
              testMode
                ? addDeviceBatchValue === '' || licenseNum <= 0
                : addDeviceBatchValue === '' || billProjectId === ''
            "
          >
            批量填入({{ addDeviceBatchArr.length }}台)
          </el-button>
          <!--          <span class="text-sm ml-2 text-gray-500">当前账单可使用数量 {{ canUseTotal }}</span>-->
        </div>
        <el-button
            class="float-right"
            size="small"
            type="danger"
            v-if="imeiData.length > 0"
            @click="clearImeiList"
        >
          清空下方表格
        </el-button>
        <InfiniteScrollTable
            v-show="imeiData.length > 0"
            ref="ref_infinite_table"
            :scrollData="imeiData"
            :getMoreData="getMoreDataFn"
            :loadConfig="loadConfig"
            @setTableData="setTableDataFn"
        >
          <el-table
              :data="tableData"
              style="width: 100%"
              height="550"
              ref="elTable"
          >
            <el-table-column prop="imei" label="IMEI">
              <template v-slot="{ row }">
                <el-input size="small" v-model="row.imei"></el-input>
              </template>
            </el-table-column>
            <!--            <el-table-column label="产品规格">-->
            <!--              <template v-slot="{row}">-->
            <!--                <el-select v-model="row.billDetailNo" clearable>-->
            <!--                  <el-option v-for="(item, index) in getProjectList()" :key="index" :label="item.projectName"-->
            <!--                             :disabled="item.disabled"-->
            <!--                             :value="item.billDetailNo" class="h-16 flex justify-between align-items-center"-->
            <!--                             style="width: 420px;">-->
            <!--                    <div class="text-sm font-bold flex flex-col leading-6 w-full">-->
            <!--                      <div>项目名称：{{ item.projectName }}</div>-->
            <!--                      <div class="text-xs font-light flex flex-row justify-between align-items-center">-->
            <!--                        <div>单价：{{ item.unitPrice }}元</div>-->
            <!--                        <div>账单剩余可用：{{ item.quantity - item.assignNum }}{{ item.unit }}</div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </el-option>-->
            <!--                </el-select>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <el-table-column width="80" label="#">
              <template v-slot="{ $index }">
                <el-button
                    size="mini"
                    type="danger"
                    @click="removeImei($index)"
                    icon="el-icon-delete"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </InfiniteScrollTable>

        <el-button
            type="primary"
            class="mt-4 w-32"
            @click="submitImei"
            :disabled="imeiData.length === 0"
        >保存
        </el-button>
      </div>
    </div>
  </el-card>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      importType: 1, // 1.快速填入IMEI号 2.设备库中选择 3.Excel表导入
      addDeviceBatchValue: '',
      imeiData: [],
      tableData: [],
      loadConfig: {
        totalCount: 0,
        itemHeight: 65
      },
      isShowAddDeviceBatch: false,
      licenseProjectList: [],
      billProjectId: '',
      licenseNum: 1,
      testNum: {}
    }
  },
  computed: {
    // 当前账单可用的总数量
    canUseTotal () {
      if (this.billInfoObj.billNo) {
        return ((this.getProjectList()).map(item => {
          return item.quantity - item.assignNum
        })).reduce((prev, cur) => {
          return prev + cur
        }, 0)
      } else {
        return 0
      }
    },
    /**
     * 将addDeviceBatchValue填入的内容转换为数组
     */
    addDeviceBatchArr () {
      let arr = this.addDeviceBatchValue.split('\n')
      arr = arr.reduce((prev, cur) => {
        cur = cur.replace(/\s*/g, '')
        if (cur) {
          return [...prev, cur]
        } else {
          return prev
        }
      }, [])
      return arr
    },
    testMode () {
      // 是否是测试模式
      return this.$route.query.testMode
    },
    ...mapGetters(['clientDeviceListMultipleList'])
  },
  props: {
    billInfoObj: {}
  },
  methods: {
    selectImportType (type) {
      this.importType = type
    },
    /**
     * 获取产品规格选项，性能待优化
     * @returns {T[]|*[]}
     */
    getProjectList () {
      if (this.billInfoObj.billNo) {
        return this.billInfoObj.curBillObj.billDetailList.map(item => {
          // 检查当前行选中的产品规格 是否超出可用数量
          const projectItemCanUse = item.quantity - item.assignNum// 当前产品规格可用数量
          const isSelectList = this.imeiData.filter(imeiItem => {
            if (imeiItem.billDetailNo === item.billDetailNo) {
              return imeiItem
            }
          }) // 当前已选中该产品规格的imei
          if (isSelectList.length === 0 && projectItemCanUse > 0) {
            item.disabled = false
          } else {
            item.disabled = isSelectList.length >= projectItemCanUse
          }
          return item
        })
      } else {
        return []
      }
    },
    /**
     * 重要逻辑
     * @returns {Promise<void>}
     */
    async addDeviceBatch () {
      // 判断列表中是否已有填写数据
      await this.checkClearImeiList()
      // 检查已填写的内容，是否有重复
      await this.checkBatchArrExist()
      // await this.checkImeiListExist()
      if (!this.testMode) {
        // 检查可用长度
        await this.checkImeiLength()
      }
      // 检查已填写的IMEI是否都在设备库中
      await this.checkImei()
      this.addDeviceBatchArr.forEach(imei => {
        this.imeiData.push({
          imei
        })
      })
      this.isShowAddDeviceBatch = false
      console.log('addDeviceBatchArr', this.addDeviceBatchArr)
    },
    checkImei () {
      const loading = this.$loading({
        lock: true,
        text: '正在检查IMEI号',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      return new Promise(resolve => {
        // 检查已填写的IMEI是否都在设备库中
        this.$api.sysDevice.checkImei({
          imeiList: this.addDeviceBatchArr.join(','),
          deviceModel: this.billInfoObj.deviceModel
        }).then(res => {
          if (res.data.length > 0) {
            const imeiStr = res.data.join('、')
            this.$message.error(`${imeiStr}填写有误，请检查已选的型号、IMEI号填写是否正确。`)
          } else {
            resolve()
          }
        }).finally(() => {
          loading.close()
        })
      })
    },
    checkImeiLength () {
      return new Promise(resolve => {
        const result = this.licenseProjectList.find(item => item.id === this.billProjectId)
        if (result) {
          console.log(result)
          if ((this.addDeviceBatchArr.length + this.imeiData.length) > result.remainNum) {
            this.$message.error(`当前可使用授权数量总数为${result.remainNum}台。`)
          } else {
            resolve()
          }
        }
      })
    },
    checkBatchArrExist () {
      return new Promise(resolve => {
        const existList = []
        this.addDeviceBatchArr.reduce((prev, cur) => {
          if (prev.indexOf(cur) > -1) {
            // 已存在
            existList.push(cur)
            return prev
          } else {
            return [...prev, cur]
          }
        }, [])
        if (existList.length > 0) {
          this.$message.error(`${existList.join('、')} 填写重复`)
        } else {
          resolve()
        }
      })
    },
    checkImeiListExist () {
      return new Promise(resolve => {
        const existList = []
        this.imeiData.forEach(item => {
          if (this.addDeviceBatchArr.indexOf(item.imei) > -1) {
            // 列表中已存在
            existList.push(item.imei)
          }
        })
        if (existList.length > 0) {
          this.$message.error(`${existList.join('、')} 已存在列表中`)
        } else {
          resolve()
        }
      })
    },
    checkClearImeiList () {
      return new Promise(resolve => {
        if (this.imeiData.length > 0) {
          this.$confirm('列表中已有数据，批量填入会将已填写数据覆盖, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.imeiData = []
            resolve()
          }).catch(() => {
          })
        } else {
          resolve()
        }
      })
    },
    getMoreDataFn () {
    },
    removeImei (index) {
      this.imeiData.splice(index, 1)
    },
    setTableDataFn (arr) {
      this.tableData = arr
    },
    async submitImei () {
      // 检查是否有输入内容
      await this.checkImeiDataEmpty()
      const loading = this.$loading({
        lock: true,
        text: '正在检查IMEI号',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let billProjectId = ''
      let feeCycle = ''
      let billProjectInfo = {}
      if (this.testMode) {
        // 测试设备直接设置有效时长
        feeCycle = this.licenseNum
      } else {
        // 正式设备根据选择的账单来赋值
        billProjectInfo = this.licenseProjectList.find(item => item.id === this.billProjectId)
        billProjectId = billProjectInfo.id
        feeCycle = billProjectInfo.feeCycle
      }
      this.$api.customerDevice.customerDeviceInsertV2({
        uniqueId: this.$route.query.uniqueId,
        billProjectId: billProjectId,
        // deviceType: this.billInfoObj.curDeviceType.deviceType,
        // deviceModel: this.billInfoObj.deviceModel,
        feeCycle: feeCycle,
        imeiList: this.imeiData.map(item => item.imei)
        // customerDeviceList: customerDeviceList
      }).then(res => {
        this.$message.success('操作成功')
        this.$store.commit('clearClientDeviceListMultipleList')
        this.$router.go(-1)
      }).finally(() => {
        loading.close()
      })
    },
    checkImeiDataEmpty () {
      return new Promise(resolve => {
        const emptyBillDetailNoArr = this.imeiData.filter(item => item.billDetailNo === '')
        if (emptyBillDetailNoArr.length > 0) {
          this.$message.error('表格内容有空选项，请检查后再提交')
        } else {
          resolve()
        }
      })
    },
    reSelect () {
      if (this.imeiData.length > 0) {
        this.$confirm('重新选择账单将会清空当前内容页, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$emit('on-re-select-bill')
        }).catch(() => {
        })
      } else {
        this.$emit('on-re-select-bill')
      }
    },
    clearImeiList () {
      this.$confirm('是否清空下方表格数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.imeiData = []
        this.addDeviceBatchValue = ''
      }).catch(() => {
      })
    },
    /**
     * 将设备页选择的设备填入
     */
    setMultipleList () {
      this.addDeviceBatchValue = (this.clientDeviceListMultipleList.map(item => item.imei)).join('\n')
      if (this.addDeviceBatchValue) {
        this.addDeviceBatch()
      }
    },
    getLicenseProjectList () {
      this.$api.bill.getLicenseProjectList({
        uniqueId: this.$route.query.uniqueId
      }).then(res => {
        this.licenseProjectList = res.data
      })
    },
    getTestNum () {
      const loading = this.$loading({
        lock: true,
        text: '正在获取测试设备数量',
        spinner: 'el-icon-loading'
      })
      this.$api.customerDevice.getTestNum().then(res => {
        this.testNum = res.data
      }).finally(() => {
        loading.close()
      })
    }
  },
  mounted () {
    if (this.testMode) {
      this.getTestNum()
    } else {
      this.getLicenseProjectList()
    }
    if (this.importType === 1) {
      this.setMultipleList()
    }
  },
  activated () {
  }
}
</script>
