<!-- 添加设备 -->
<template>
  <div>
    <blockTitle title="分配设备">
      <template slot="customContent">
        <el-button size="mini" class="ml-4" @click="$router.go(-1)"
        >返回设备列表
        </el-button>
      </template>
    </blockTitle>
    <!--    <selectBill v-if="step === 1" @on-submit="selectBillOnSubmit" ref="selectBill"></selectBill>-->
    <importImei
        @on-re-select-bill="reSelectBill"
        :billInfoObj="stepOneObj"
        @on-submit="importImeiOnSubmit"
        ref="importImei"
    ></importImei>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
// import selectBill from 'components/client/clientAddDevice/selectBillV2'
import importImei from 'components/client/clientAddDevice/importImeiV2'

export default {
  data () {
    return {
      step: 1,
      stepOneObj: {}
    }
  },
  components: {
    // selectBill,
    importImei
  },
  methods: {
    selectBillOnSubmit (params) {
      this.stepOneObj = params
      console.log('params', params)
      this.step = 2
    },
    importImeiOnSubmit () {
    },
    reSelectBill () {
      this.stepOneObj = {}
      this.step = 1
    }
  },
  mounted () {
    // window.onbeforeunload = function (e) {
    //   const dialogText = '刷新将会丢失已选设备信息'
    //   e.returnValue = dialogText
    //   return dialogText
    // }
  },
  beforeRouteLeave (to, from, next) {
    to.meta.isBack = to.name === 'clientDetails'
    next()
  }
}
</script>
